import React, { createRef, useMemo, useState, useEffect, createContext } from "react";

//css
import "./static/css/login.css";

//Librerias
import Routes from "./Router";
import { isIE } from "react-device-detect";
import Player from "react-lottie-player";
import typeConvertor from "string-type-convertor";

//Componentes de Material UI
import {
    CssBaseline,
    Typography,
    Button,
    useMediaQuery,
    darkScrollbar,
} from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Componente de notistack
import { SnackbarProvider } from "notistack";

//Componente de Material Lab
import { LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";

//Componentes de DateFns
import { es } from "date-fns/locale";

//Componente de Permisos
import { AbilityContext } from "./config/Can";
import { Ability } from "@casl/ability";
import CacheBuster from "./middlewares/cacheBuster";

const ability = new Ability();
export const AppContext = createContext();

const App = () => {
    const [bitDarkMode, setBitDarkMode] = useState(true);

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    useEffect(() => {
        let storage = typeConvertor(localStorage.getItem("bitDarkMode"));

        let bitDarkMode = typeof storage === "boolean" ? storage : prefersDarkMode;

        setBitDarkMode(bitDarkMode);
    }, [prefersDarkMode]);

    const themeOptions = useMemo(
        () =>
            createMuiTheme({
                palette: {
                    mode: bitDarkMode ? "dark" : "light",
                    primary: {
                        main: "#8bc34a",
                        light: "#dcedc8",
                        dark: "#689f38",
                        contrastText: "#212121",
                    },
                    secondary: {
                        main: "#ff5722",
                    },
                    divider: "#BDBDBD",
                },
                components: {
                    MuiCssBaseline: {
                        styleOverrides: {
                            body: bitDarkMode ? darkScrollbar() : null,
                        },
                    },
                },
            }),
        [bitDarkMode]
    );

    if (isIE) {
        return (
            <div className="container">
                <div className="item">
                    <Player
                        play
                        loop
                        animationData={require("./static/json/animationMaintenance.json")}
                        style={{ height: "400px", width: "800px" }}
                    />
                </div>

                <div>
                    <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        style={{ color: "#459fc2" }}
                    >
                        <b>Navegador no soportado.</b>
                    </Typography>
                </div>

                <div>
                    <Typography component="p" variant="subtitle1">
                        Lo sentimos, está aplicación solo puede ser ejecutada en
                        navegadores de ultima generación, por favor intenta abrirla en un
                        navegador diferente.
                    </Typography>
                </div>
            </div>
        );
    }

    const notistackRef = createRef();

    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;

                if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                }

                return (
                    <AppContext.Provider
                        value={{
                            setBitDarkMode,
                            bitDarkMode,
                        }}
                    >
                        <ThemeProvider theme={themeOptions}>
                            <AbilityContext.Provider value={ability}>
                                <CssBaseline />
                                <SnackbarProvider
                                    ref={notistackRef}
                                    action={(key) => (
                                        <Button onClick={onClickDismiss(key)}>
                                            <b>
                                                <span style={{ color: "white" }}>
                                                    Cerrar
                                                </span>
                                            </b>
                                        </Button>
                                    )}
                                >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={es}
                                    >
                                        <Routes />
                                    </LocalizationProvider>
                                </SnackbarProvider>
                            </AbilityContext.Provider>
                        </ThemeProvider>
                    </AppContext.Provider>
                );
            }}
        </CacheBuster>
    );
};

export default App;
