import React, { lazy, Suspense } from "react";

//Librerias
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Middlewares
import Auth from "./middlewares/Auth";
import ErrorBoundary from "./middlewares/ErrorBoundary";

//Componentes
import Loading from "./components/Loader";

//===============================================================================================================================================
//========================================== Rutas principales  =================================================================================
//===============================================================================================================================================
const RoutesCatalogo = lazy(() => import("./routes/catalogo.routes"));

//===============================================================================================================================================
//========================================== Otras rutas ========================================================================================
//===============================================================================================================================================
const PageNotFound = lazy(() => import("./components/Error/404"));
const Login = lazy(() => import("./containers/Login"));

const Routes = () => {
    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    return (
        <Auth>
            <Router>
                <ErrorBoundary>
                    <Suspense fallback={<Loading />}>
                        <Switch>
                            <Route path="/" exact component={Login} />

                            <RoutesCatalogo path="/catalogo" />

                            <Route path="*" component={PageNotFound} />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </Router>
        </Auth>
    );
};

export default Routes;
