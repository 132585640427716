import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

//Componentes de Material UI
import { StylesProvider } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Componentes
import App from "./app";

const themeOptions = createMuiTheme();

ReactDOM.render(
    <StylesProvider injectFirst>
        <ThemeProvider theme={themeOptions}>
            <App />
        </ThemeProvider>
    </StylesProvider>,
    document.getElementById("root")
);
